import * as Sentry from '@sentry/gatsby';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { AgentProfile } from '../../@types/AgentProfile';

/**
 * TODO: once auth is ironed out with the new Agent Service, we'll need
 * to create a new axios instance w/interceptors accordingly.
 */
const baseURL = process.env.AGENT_SERVICE_API_URL;

const agentInstance = axios.create({
  baseURL,
  timeout: 0,
});

export const fetchAgentProfilebyId = createAsyncThunk<AgentProfile, string>(
  'agentProfile/fetchAgentProfilebyId',
  async (agentId, { rejectWithValue }) => {
    try {
      const response = await agentInstance.get(`/agents/${agentId}`, {
        headers: { 'Apicraft-Mock': true },
      });

      return response.data;
    } catch (error) {
      Sentry.captureException(error);

      const message =
        error instanceof Error ? error.message : 'Something went wrong.';
      return rejectWithValue(message);
    }
  },
);
