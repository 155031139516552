import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/gatsby';
import instance from '../../AxiosInstance';
import { FeatureFlag } from '../../@types/FeatureFlag';

export const fetchFFAgentProfileEnabled = createAsyncThunk<
  FeatureFlag,
  void,
  { rejectValue: string }
>('featureFlags/fetchFFAgentProfileEnabled', async (_, { rejectWithValue }) => {
  try {
    const response = await instance.get('/feature-flags/agent_profile_enabled');
    return response.data;
  } catch (error: any) {
    Sentry.captureException(error);
    const val = error.message || error;
    return rejectWithValue(val);
  }
});
