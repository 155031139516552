export const AGENT_EXPECTATIONS = [
  'Try to connect with the Loan Officer first, then call the customer.',
  "Notify the Loan Officer and Clever immediately if the customer indicates they're shopping for other lenders.",
  'Update your agent portal every week.',
];

export const AGENT_PROFILE_BIO =
  'Help the Clever team, potential clients, and partners (such as Loan Officers) get to know you better with a short bio. Include details like your years of experience, sales numbers, areas served, team size, specialties, etc.';
export const AGENT_PROFILE_URL =
  'Build rapport with the Clever team, potential clients, and partners (such as Loan Officers) by providing a link to an external profile, like Zillow, where we can view your sales and client reviews.';
