import { createSlice } from '@reduxjs/toolkit';
import { AgentProfile } from '../../@types/AgentProfile';
import { fetchAgentProfilebyId } from './actions';

const initialState = {
  error: '',
  hasErrors: false,
  loading: false,
  agentProfile: null as AgentProfile | null,
};

const agentProfileSlice = createSlice({
  name: 'agentProfile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAgentProfilebyId.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAgentProfilebyId.fulfilled, (state, { payload }) => {
      state.agentProfile = payload;
      state.hasErrors = false;
      state.loading = false;
    });
    builder.addCase(fetchAgentProfilebyId.rejected, (state, { payload }) => {
      state.hasErrors = true;
      state.loading = false;
      state.error = payload as string;
    });
  },
});

export default agentProfileSlice.reducer;
